<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  watch: {
    $route(to, from) {
      if (to.path !== from.path && to.meta.title) {
        this.metaChange(to.meta.title);
      }
    },
  },

  created() {
    // メタタイトルの設定
    const title = this.$route.meta.title;
    if (title) {
      this.metaChange(title);
    }
  },

  methods: {
    metaChange(val) {
      document.title = val;
    },
  },
};
</script>
