<template>
  <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
    <ul class="breadcrumb breadcrumb-links breadcrumb-dark">
      <li class="breadcrumb-item">
        <router-link :to="{ name: 'site_list' }" class="text-white">
          <i class="fas fa-home"></i>
        </router-link>
      </li>

      <li v-if="parentName" class="breadcrumb-item">
        <router-link :to="{ name: parentName }" class="text-white">
          {{ $t("dashboard.feature." + parentName) }}
        </router-link>
      </li>

      <li class="breadcrumb-item text-white active">
        {{ $t("dashboard.feature." + routeName) }}
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  computed: {
    routeName() {
      return this.$route.name;
    },

    /** 親ページの有無判定 */
    parentName() {
      if (this.$route.meta.breadcrumb) {
        return this.$route.meta.breadcrumb.parent;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss"></style>
