<template>
  <base-card header-classes="border-0" footerClasses="border-0 mb-4">
    <template v-slot:header>
      <!-- <div class="d-md-flex justify-content-between"> -->
      <h3>{{ $t("dashboard.title.report") }}</h3>
      <div v-if="report && report.content" class="mt-5">
        <p style="white-space: pre-line">
          {{ $t("dashboard.message.report_summary") }}
        </p>
        <base-input formClasses="d-inline-block mb-0 mr-4">
          <select class="form-control" v-model="month" @change="reportShow()">
            <option
              v-for="date in report.date_coverage"
              :key="date"
              :value="date"
            >
              {{ date }}
            </option>
          </select>
        </base-input>
        <base-button
          v-if="btn_active"
          :type="null"
          class="iv-partner-btn"
          @click="reportDownload"
        >
          {{
            $t("general.do_something", {
              name: $t("dashboard.items.download"),
            })
          }}
          <i class="fas fa-download ml-2"></i>
        </base-button>
        <base-button v-else :type="null" class="iv-partner-btn" disabled>
          {{ $t("dashboard.items.download_now") }}
        </base-button>
      </div>
      <!-- </div> -->
    </template>
    <div v-if="report && report.content" v-html="report.content"></div>
    <div v-else-if="report === null" class="text-center">
      <h3>{{ $t("dashboard.message.not_report") }}</h3>
      <p class="m-0" style="white-space: pre-line">
        {{ $t("dashboard.message.not_report_summary") }}
      </p>
    </div>
    <template v-slot:footer>
      <div v-if="report && report.content">
        <base-input formClasses="d-inline-block mb-0 mr-4">
          <select class="form-control" v-model="month" @change="reportShow()">
            <option
              v-for="date in report.date_coverage"
              :key="date"
              :value="date"
            >
              {{ date }}
            </option>
          </select>
        </base-input>
        <base-button
          v-if="btn_active"
          :type="null"
          class="iv-partner-btn"
          @click="reportDownload"
        >
          {{
            $t("general.do_something", {
              name: $t("dashboard.items.download"),
            })
          }}
          <i class="fas fa-download ml-2"></i>
        </base-button>
        <base-button v-else :type="null" class="iv-partner-btn" disabled>
          {{ $t("dashboard.items.download_now") }}
        </base-button>
      </div>
    </template>
  </base-card>
</template>

<script>
export default {
  data() {
    return {
      site_id: this.$route.params["site_id"],
      btn_active: true,
      report: {},
      month: this.$moment().add(-1, "month").format("YYYY-MM"),
      download: {
        year: "",
        monthly: "",
      },
    };
  },

  created() {
    /** レポートデータ取得 */
    this.reportShow();
  },

  methods: {
    reportShow() {
      this.report = {};
      const ReportRepository = this.$repository.get("report");
      const report = ReportRepository.index(this.month, this.site_id);
      const self = this;
      report.then(
        function (result) {
          const res = result.data.response.data;
          if (Object.keys(res).length) {
            self.report = res;
          } else {
            self.report = null;
          }
          self.month = self.$moment(res.date_period).format("YYYY-MM");
          self.download.year = self.$moment(res.date_period).format("YYYY");
          self.download.monthly = self.$moment(res.date_period).format("MM");
        },
        function (error) {
          const res = error.response;
          if (res.status === 401) {
            /** 認証失敗時の処理 */
            self.$cookies.remove("partner_access_token");
            self.$cookies.remove("partner_contractor_id");
            self.$router.push({ name: "login" });
          } else {
            self.$router.push("/" + res.status);
          }
        }
      );
    },
    reportDownload() {
      /** PDFダウンロード処理 */
      this.btn_active = false;
      const ReportRepository = this.$repository.get("report");
      const download = ReportRepository.download(this.month, this.site_id);
      const self = this;
      download.then(
        function (result) {
          const res = result.data;
          let blob = new Blob([res], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = self.$t("dashboard.items.report_title", {
            year: self.download.year,
            month: self.download.monthly,
          });
          link.click();
          self.btn_active = true;
        },
        function () {
          self.$swal({
            icon: "warning",
            title: self.$t("general.error", {
              name: self.$t("dashboard.items.report"),
              verb: self.$t("dashboard.items.download"),
            }),
            confirmButtonText: self.$t("general.close"),
            timer: 5000,
          });
          self.btn_active = true;
        }
      );
    },
  },
};
</script>

<style lang="scss"></style>
