import Repository from "./repository";

export default {
  index(token) {
    return Repository.get("/auth", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};
