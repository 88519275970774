export default {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // 該当要素外をクリックしたことを判別
      if (!(el == event.target || el.contains(event.target))) {
        // 指定したメソッドの呼び出し
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
};
