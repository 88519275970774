import Repository from "./repository";

const resource = "/password";

export default {
  email(val) {
    return Repository.post(`${resource}/email`, {
      email: val,
    });
  },

  integration(fields) {
    return Repository.get(`${resource}/integration`, {
      params: {
        email: fields.email,
        access_key: fields.access_key,
      },
    });
  },

  reset(fields) {
    return Repository.post(`${resource}/reset`, {
      email: fields.email,
      password: fields.password,
      confirm_password: fields.confirm_password,
      access_key: fields.access_key,
    });
  },
};
