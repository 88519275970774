export default {
  ja: {
    general: {
      submit: "送信",
      update: "更新",
      change: "変更",
      do_something: "{name}する",
      message: "{name}を{verb}しました。",
      error: "{name}を{verb}することが出来ませんでした。",
      page_back: "{name}へ戻る",
      close: "閉じる",
      end: "終了",
      console: "管理画面",
    },
    auth: {
      title: {
        login: "ログイン",
        reset: "ログインパスワード再設定",
      },
      items: {
        login_now: "ログイン中...",
        return_login: "ログイン画面に戻る",
        forget_password: "パスワードをお忘れですか？",
        email: "メールアドレス",
        password: "パスワード",
        confirm_password: "パスワード（確認用）",
        reset_password: "パスワードを再設定",
      },
      message: {
        password: "半角英数字および半角記号のみ入力してください。",
        login: "メールアドレスとパスワードを入力して管理画面にログインします。",
        email:
          "ログイン時に使用しているメールアドレスを入力してください。パスワード再設定ページのURLをメールでお送りします。",
        reset: "URLの発行から60分以内に再設定してください。",
        unauthorize: "メールアドレスもしくはパスワードが間違っています",
      },
    },
    dashboard: {
      feature: {
        site_list: "サイト一覧",
        site_show: "サイト詳細",
        profile: "プロフィール",
      },
      title: {
        info: "基本情報",
        report: "アクセスレポート",
        partner: "パートナーメニュー",
        profile: "プロフィール設定",
        password: "パスワード変更",
      },
      items: {
        user: "担当者",
        origin: "サイトURL",
        site_id: "サイトID",
        contract: "契約区分",
        plan: "プラン",
        theme: "テーマ",
        theme_color: "テーマカラー",
        option: "オプション",
        not_used: "ご利用なし",
        limit_freepage: "フリーページ上限数",
        limit_mail_account: "メールアカウント上限数",
        contractor: "契約者名",
        open_at: "サイト利用開始日",
        filter: "絞り込み",
        sort: "並び替え",
        sort_report: "レポート指標",
        order: "昇順",
        order_reverse: "降順",
        report: "レポート",
        download: "ダウンロード",
        download_now: "ダウンロード中...",
        report_title: "{year}年{month}月度アクセス解析レポート",
        search: "検索",
        select_all: "すべて",
        email: "メールアドレス",
        password: "パスワード",
        old_password: "現在のパスワード",
        new_password: "新しいパスワード",
        new_confirm_password: "新しいパスワード（確認用）",
        permission: "アカウント権限",
        logout: "ログアウト",
      },
      message: {
        not_search: "検索結果に該当するサイトが見つかりませんでした。",
        not_search_summary: "検索条件を変更して再度検索してください。",
        not_report: "アクセス解析レポートのデータがありません。",
        report_summary:
          "このレポートではホームページがどのようにアクセスされているのかなどの集客情報がわかります。\r\nホームページがどのくらい見られ、ユーザーがどのような流入経路でアクセスしているのかを分析することができます。",
        not_report_summary:
          "サイトを当月発行した場合は、翌月以降よりレポートが表示されます。\r\nアナリティクス連携が未設定の場合は、サイト管理画面から設定を行ってください。",
      },
      report: {
        rate: "前月比",
        data: [
          {
            segment: "users",
            title: "ユーザー数",
            unit: "人",
            color: "green",
            icon: "fas fa-user",
          },
          {
            segment: "pageviews",
            title: "ページビュー数",
            unit: "PV",
            color: "danger",
            icon: "fas fa-search",
          },
          {
            segment: "bounce_rate",
            title: "直帰率",
            unit: "%",
            color: "purple",
            icon: "fas fa-sign-out-alt",
          },
          {
            segment: "avg_session_duration",
            title: "平均滞在時間",
            unit: "",
            color: "primary",
            icon: "fas fa-clock",
          },
        ],
      },
    },
    error: {
      400: "Bad Request",
      401: "Unauthorized",
      403: "Forbidden",
      404: "Not Found",
      500: "Internal Server Error",
      502: "Bad Gateway",
      503: "Service Unavailable",
    },
  },
};
