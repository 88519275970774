<template>
  <div
    class="form-group"
    :class="[
      { 'has-danger': error },
      { 'has-label': label || $slots.label },
      { 'has-success': valid === true },
      { 'has-danger': valid === false },
      formClasses,
    ]"
  >
    <div
      :class="[
        { 'input-group': hasIcon },
        { focused: focused },
        inputGroupClasses,
      ]"
    >
      <slot name="label">
        <label v-if="label" class="form-control-label" :class="labelClasses">
          {{ label }}
          <span v-if="required">*</span>
        </label>
      </slot>

      <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
        <span class="input-group-text">
          <slot name="addonLeft">
            <i :class="addonLeftIcon"></i>
          </slot>
        </span>
      </div>
      <slot>
        <input
          @input="updateValue"
          @focus="onFocus"
          @blur="onBlur"
          :value="value"
          v-bind="$attrs"
          class="form-control"
          :type="inputType"
          :class="[
            { 'is-valid': valid === true },
            { 'is-invalid': valid === false },
            inputClasses,
          ]"
          aria-describedby="addon-right addon-left"
        />
      </slot>
      <div
        v-if="addonRightIcon || $slots.addonRight"
        class="input-group-append"
      >
        <span class="input-group-text">
          <slot name="addonRight">
            <i v-if="!password" :class="addonRightIcon"></i>
            <i
              v-if="password"
              class="iv-password__preview"
              :class="passwordIcon"
              @click="viewChange"
              style="cursor: pointer"
            ></i>
          </slot>
        </span>
      </div>
      <slot name="infoBlock"></slot>
      <slot name="helpBlock">
        <div
          class="text-danger invalid-feedback"
          style="display: block"
          :class="{ 'mt-2': hasIcon }"
          v-if="error && errorActive"
        >
          {{ error }}
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  name: "base-input",

  props: {
    required: {
      type: Boolean,
      description: "Whether input is required (adds an asterix *)",
    },
    valid: {
      type: Boolean,
      description: "Whether is valid",
      default: undefined,
    },
    type: {
      type: String,
      description: "Input type",
    },
    label: {
      type: String,
      description: "Input label (text before input)",
    },
    error: {
      type: String,
      description: "Input error (below input)",
    },
    formClasses: {
      type: String,
      description: "Form css classes",
    },
    inputGroupClasses: {
      type: String,
      description: "Input css classes",
    },
    labelClasses: {
      type: String,
      description: "Input label css classes",
    },
    inputClasses: {
      type: String,
      description: "Input css classes",
    },
    value: {
      type: [String, Number],
      description: "Input value",
    },
    addonLeftIcon: {
      type: String,
      description: "Addont left icon",
    },

    // パスワードのプレビューを使う際は必須
    addonRightIcon: {
      type: String,
      description: "Addon right icon",
    },
    // パスワードのプレビューを使う際は'true'を定義
    password: {
      type: Boolean,
      default: false,
      description: "Password view change",
    },
    errorActive: {
      type: Boolean,
      default: true,
      description: "Use error message",
    },
  },

  data() {
    return {
      focused: false,
      passwordIcon: "fa fa-eye-slash",
      inputType: this.type,
    };
  },

  computed: {
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
  },

  methods: {
    updateValue(evt) {
      let value = evt.target.value;
      this.$emit("input", value);
    },
    onFocus(value) {
      this.focused = true;
      this.$emit("focus", value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit("blur", value);
    },
    viewChange() {
      if (this.inputType == "text") {
        this.inputType = "password";
        this.passwordIcon = "fa fa-eye-slash";
      } else {
        this.inputType = "text";
        this.passwordIcon = "fa fa-eye";
      }
    },
  },
};
</script>

<style lang="scss"></style>
