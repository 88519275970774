<template>
  <base-card>
    <form @submit.prevent="submit">
      <h4 class="mb-4">{{ $t("dashboard.items.filter") }}</h4>
      <div class="row">
        <label class="col-md-2 form-control-label">
          {{ $t("dashboard.items.plan") }}
        </label>
        <div class="col-md-4">
          <base-input input-classes="form-control-sm">
            <select class="form-control form-control-sm" v-model="fields.plan">
              <option value="">
                {{ $t("dashboard.items.select_all") }}
              </option>
              <option
                v-for="plan in $store.state.master.plans"
                :key="plan.id"
                :value="plan.id"
              >
                {{ plan.name }}
              </option>
            </select>
          </base-input>
        </div>
      </div>
      <div class="row">
        <label class="col-md-2 form-control-label">
          {{ $t("dashboard.items.origin") }}
        </label>
        <div class="col-md-4">
          <base-input
            type="text"
            input-classes="form-control-sm"
            placeholder="https://example.com"
            v-model="fields.origin_name"
            data-origin
          >
          </base-input>
        </div>
      </div>
      <hr />
      <h4 class="mb-4">{{ $t("dashboard.items.sort") }}</h4>
      <div class="form-row">
        <label class="col-md-2 form-control-label">
          {{ $t("dashboard.items.sort_report") }}
        </label>
        <div class="col-md-4 col-lg-2">
          <base-input input-classes="form-control-sm">
            <select
              class="form-control form-control-sm"
              v-model="fields.resources_type"
            >
              <option
                v-for="report in $t('dashboard.report.data')"
                :key="report.segment"
                :value="report.segment"
              >
                {{ report.title }}
              </option>
            </select>
          </base-input>
        </div>
        <div class="col-md-3 ml-2">
          <div class="custom-control custom-radio custom-control-inline">
            <input
              class="custom-control-input"
              type="radio"
              id="order_reverse"
              value="desc"
              v-model="fields.resources_order"
            />
            <label class="custom-control-label" for="order_reverse">
              {{ $t("dashboard.items.order_reverse") }}
            </label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input
              class="custom-control-input"
              type="radio"
              id="order"
              value="asc"
              v-model="fields.resources_order"
            />
            <label class="custom-control-label" for="order">
              {{ $t("dashboard.items.order") }}
            </label>
          </div>
        </div>
      </div>
      <div class="text-right">
        <base-button :type="null" class="iv-partner-btn" native-type="submit">
          {{
            $t("general.do_something", { name: $t("dashboard.items.search") })
          }}
        </base-button>
      </div>
    </form>
  </base-card>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        plan: "",
        origin_name: "",
        resources_type: "",
        resources_order: "desc",
      },
    };
  },

  methods: {
    submit() {
      this.$emit("searchActive", this.fields);
    },
  },
};
</script>

<style lang="scss"></style>
