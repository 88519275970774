<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <base-card
        class="border-0 mb-0"
        type="secondary"
        header-classes="bg-transparent py-4"
        body-classes="px-lg-5 py-lg-3"
      >
        <template v-slot:header>
          <div class="text-center mt-2">
            <small>{{ $t("auth.title.login") }}</small>
          </div>
        </template>
        <div v-if="doError" class="alert alert-danger">
          <ul class="mb-0 list-unstyled">
            <li>{{ error.email }}</li>
            <li>{{ error.password }}</li>
            <li v-if="unauthorize">
              {{ message }}
            </li>
          </ul>
        </div>
        <form @submit.prevent="submit">
          <base-input
            type="email"
            form-classes="mb-3"
            input-group-classes="input-group-merge"
            :placeholder="$t('auth.items.email')"
            addon-left-icon="ni ni-email-83"
            v-model="fields.email"
            :error="error.email"
            :error-active="false"
            :valid="validate"
          >
          </base-input>
          <base-input
            :password="true"
            type="password"
            form-classes="mb-3"
            input-group-classes="input-group-merge"
            :placeholder="$t('auth.items.password')"
            addon-left-icon="ni ni-lock-circle-open"
            addon-right-icon="fa fa-eye"
            v-model="fields.password"
            :error="error.password"
            :error-active="false"
            :valid="validate"
          >
          </base-input>
          <div class="text-center">
            <base-button
              v-if="btn_active"
              :type="null"
              class="iv-partner-btn my-4"
              native-type="submit"
            >
              {{ $t("general.do_something", { name: $t("auth.title.login") }) }}
            </base-button>
            <base-button
              v-else
              :type="null"
              class="iv-partner-btn my-4"
              disabled
            >
              {{ $t("auth.items.login_now") }}
            </base-button>
          </div>
        </form>
      </base-card>

      <!-- パスワードリセットリンク -->
      <!-- <div class="row mt-3">
        <div class="col-6">
          <router-link :to="{ name: 'email' }">
            <small>{{ $t("auth.items.forget_password") }}</small>
          </router-link>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        email: "",
        password: "",
      },
      doError: false,
      validate: null,
      error: {
        email: "",
        password: "",
      },
      unauthorize: false,
      btn_active: true,
      message: "",
      expiration: "",
    };
  },

  methods: {
    submit() {
      // データ初期化
      this.doError = false;
      this.btn_active = false;
      this.error.email = "";
      this.error.password = "";
      this.unauthorize = "";
      this.message = "";

      const LoginRepository = this.$repository.get("login");
      const login = LoginRepository.login(this.fields);
      const self = this;
      login.then(
        function (result) {
          const res = result.data.response;
          self.expiration = res.expires_in;
          // アクセストークンをcookieにセット
          self.$cookies.set(
            "partner_access_token",
            res.access_token,
            self.expiration
          );
          const authRepository = self.$repository.get("auth");
          const auth = authRepository.index(
            self.$cookies.get("partner_access_token")
          );
          auth.then(
            function (result) {
              const res = result.data.response;
              // パートナーIDをcookieにセット
              self.$cookies.set(
                "partner_contractor_id",
                res.contractor_id,
                self.expiration
              );
              self.$store.dispatch("setCookie");
              self.$router.go({ path: "/sites", force: true });
            },
            function (error) {
              const res = error.response;
              self.$router.push("/" + res.status);
            }
          );
        },
        function (error) {
          const res = error.response;
          if (res.status === 422) {
            /** バリデーション処理 */
            if (res.data.errors.username) {
              self.error.email = res.data.errors.username[0];
            }
            if (res.data.errors.password) {
              self.error.password = res.data.errors.password[0];
            }
            self.doError = true;
            self.validate = false;
          } else if (res.status === 401) {
            /** 認証失敗時の処理 */
            self.unauthorize = true;
            self.message = res.data.errors;
            self.doError = true;
            self.validate = false;
          } else {
            self.$router.push("/" + res.status);
          }
          self.btn_active = true;
        }
      );
    },
  },
};
</script>

<style lang="scss"></style>
