import Repository from "./repository";
import store from "@/store/index.js";

const resource = "/report";

export default {
  index(month, id) {
    const contractorId = store.state.id;
    return Repository.get(`${contractorId}${resource}/${id}`, {
      params: {
        month: month,
      },
    });
  },

  download(month, id) {
    const contractorId = store.state.id;
    return Repository.get(`${contractorId}${resource}/${id}/download`, {
      params: {
        month: month,
      },
      responseType: "blob",
    });
  },
};
