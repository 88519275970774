<template>
  <div class="iv-partner__error">
    <div class="d-flex mb-4">
      <div class="iv-partner__error--code">{{ code }}</div>
      <div class="iv-partner__error--message">{{ $t(`error.${code}`) }}</div>
    </div>
    <base-button v-if="show" type="default">
      <router-link :to="{ name: 'site_list' }" class="text-white">
        {{ $t("general.page_back", { name: $t("general.console") }) }}
      </router-link>
    </base-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      code: this.$route.name,
      show: "",
    };
  },

  created() {
    if (this.code.slice(0, 1) === "4") {
      this.show = true;
    }
  },
};
</script>

<style lang="scss">
.iv-partner__error {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  flex-direction: column;

  &--code {
    border-right: 2px solid;
    font-size: 26px;
    padding: 0 15px 0 15px;
    text-align: center;
  }

  &--message {
    font-size: 18px;
    text-align: center;
    padding: 10px;
  }
}
</style>
