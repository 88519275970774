import Vue from "vue";
import Router from "vue-router";

// Layout
import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

// Views
import SiteList from "@/views/SiteList.vue";
import SiteShow from "@/views/SiteShow.vue";
import Profile from "@/views/Profile.vue";

import Login from "@/views/Login.vue";
// import Email from "@/views/password/Email.vue";
// import Reset from "@/views/password/Reset.vue";

import Error from "@/system/Error.vue";

// Store
import Store from "@/store/index.js";

Vue.use(Router);

const tmp = document.title;
const title = " - " + tmp;

const routes = [
  {
    path: "/",
    redirect: "/sites",
    component: DashboardLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/sites",
        name: "site_list",
        components: { default: SiteList },
        meta: { title: "サイト一覧" + title },
      },
      {
        path: "/sites/:site_id",
        name: "site_show",
        components: { default: SiteShow },
        meta: {
          title: "サイト詳細" + title,
          breadcrumb: {
            parent: "site_list",
          },
        },
      },
      {
        path: "/profile",
        name: "profile",
        components: { default: Profile },
        meta: { title: "プロフィール" + title },
      },
    ],
  },
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
        meta: { title: "ログイン" + title },
      },
      // {
      //   path: "/password/reset",
      //   name: "email",
      //   components: { default: Email },
      //   meta: { title: "パスワード再設定" + title },
      // },
      // {
      //   path: "/password/reset/:access_key",
      //   name: "reset",
      //   components: { default: Reset },
      //   meta: { title: "パスワード再設定" + title },
      // },
    ],
  },
  {
    path: "/400",
    name: "400",
    component: Error,
  },
  {
    path: "/401",
    name: "401",
    component: Error,
  },
  {
    path: "/403",
    name: "403",
    component: Error,
  },
  {
    path: "/500",
    name: "500",
    component: Error,
  },
  {
    path: "/503",
    name: "503",
    component: Error,
  },
  {
    path: "/502",
    name: "502",
    component: Error,
  },
  {
    path: "*",
    name: "404",
    component: Error,
  },
];

const router = new Router({
  mode: "history",
  routes,
});

// トークンが存在しない場合はログイン画面を表示
router.beforeEach((to, from, next) => {
  if (
    (to.matched.some((record) => record.meta.requiresAuth) &&
      !Store.state.token) ||
    (to.matched.some((record) => record.meta.requiresAuth) && !Store.state.id)
  ) {
    next({ path: "/login" });
  } else {
    next();
  }
});

export default router;
