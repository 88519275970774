import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";
import { data } from "./assets/lang/index";
import VueCookies from "vue-cookies";
import Dashboard from "./plugins/dashboard";
import VueMoment from "vue-moment";
import { RepositoryFactory } from "./repositories/repositoryFactory";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "./assets/css/iv-partner.css";

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(Dashboard);
Vue.use(VueMoment);

/** リポジトリパターンの登録 */
Vue.prototype.$repository = RepositoryFactory;

/** sweetalertの設定 */
const options = {
  confirmButtonColor: "#F1641A",
};
Vue.use(VueSweetalert2, options);

/** languageファイルの設定 */
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: "ja",
  messages: data,
});

new Vue({
  router,
  store,
  i18n: i18n,
  render: (h) => h(App),
}).$mount("#app");
