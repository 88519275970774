import Vue from "vue";
import Vuex from "vuex";
import Cookies from "vue-cookies";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: Cookies.get("partner_access_token"),
    id: Cookies.get("partner_contractor_id"),
    master: {
      options: JSON.parse(localStorage.getItem("iv_mst_options")),
      plans: JSON.parse(localStorage.getItem("iv_mst_plans")),
      themes: JSON.parse(localStorage.getItem("iv_mst_themes")),
      theme_colors: JSON.parse(localStorage.getItem("iv_mst_theme_colors")),
      contractor_types: JSON.parse(
        localStorage.getItem("iv_mst_contractor_types")
      ),
      resource_types: JSON.parse(localStorage.getItem("iv_mst_resource_types")),
    },
  },

  mutations: {
    setCookie(state) {
      state.token = Cookies.get("partner_access_token");
      state.id = Cookies.get("partner_contractor_id");
    },

    setMaster(state) {
      state.master.options = JSON.parse(localStorage.getItem("iv_mst_options"));
      state.master.plans = JSON.parse(localStorage.getItem("iv_mst_plans"));
      state.master.themes = JSON.parse(localStorage.getItem("iv_mst_themes"));
      state.master.theme_colors = JSON.parse(
        localStorage.getItem("iv_mst_theme_colors")
      );
      state.master.contractor_types = JSON.parse(
        localStorage.getItem("iv_mst_contractor_types")
      );
      state.master.resource_types = JSON.parse(
        localStorage.getItem("iv_mst_resource_types")
      );
    },
  },

  actions: {
    setCookie({ commit }) {
      commit("setCookie");
    },

    setMaster({ commit }) {
      commit("setMaster");
    },
  },
});
