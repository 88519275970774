<template>
  <nav
    class="
      sidenav
      navbar navbar-vertical
      fixed-left
      navbar-expand-xs navbar-light
      bg-white
      overflow-hidden
    "
    id="sidenav-main"
    @mouseover="hoverSidebar(true)"
    @mouseleave="hoverSidebar(false)"
  >
    <div
      class="scroll-wrapper scrollbar-inner overflow-auto"
      style="position: relative"
    >
      <div
        class="scrollbar-inner overflow-auto scroll-content"
        style="
          height: 953px;
          margin-bottom: 0px;
          margin-right: 0px;
          max-height: none;
        "
      >
        <!-- Brand -->
        <div class="sidenav-header d-flex align-items-center">
          <router-link :to="{ name: 'site_list' }" class="navbar-brand">
            <img :src="storage + logo" class="navbar-brand-img" />
          </router-link>
          <div class="ml-auto">
            <!-- Sidenav toggler -->
            <div
              @click="toggleMenu()"
              :class="{ active: showMenu }"
              class="sidenav-toggler d-none d-xl-block"
            >
              <div class="sidenav-toggler-inner">
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
                <i class="sidenav-toggler-line"></i>
              </div>
            </div>
            <div @click="closeMenu()" class="sidenav-toggler d-md-none pr-4">
              <button type="button" class="close">
                <span class="text-xl">×</span>
              </button>
            </div>
          </div>
        </div>
        <div class="navbar-inner">
          <!-- Collapse -->
          <div class="collapse navbar-collapse" id="sidenav-collapse-main">
            <!-- Nav items -->
            <ul class="navbar-nav">
              <li v-for="nav in navigation" :key="nav.id" class="nav-item">
                <router-link
                  :to="{ name: nav.name }"
                  class="nav-link"
                  :class="{ active: routeName == nav.name }"
                >
                  <i :class="nav.icon"></i>
                  <span class="nav-link-text">
                    {{ $t(`dashboard.feature.${nav.name}`) }}
                  </span>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false,
      storage: "",
      logo: "",
      routeName: "",
      navigation: [
        {
          id: 1,
          name: "site_list",
          icon: "fas fa-home",
        },
      ],
    };
  },

  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        this.getRoute();
      }
    },
  },

  created() {
    this.storage = process.env.VUE_APP_STORAGE_URL;
    this.logo = process.env.VUE_APP_STORAGE_LOGO_BLACK_URL;

    /** サイドナビゲーションの状態取得 */
    const sidenav = this.$cookies.get("partner_sidenav");
    if (sidenav == "pinned") {
      this.showMenu = true;
    } else {
      this.showMenu = false;
    }

    if (this.$route.meta.breadcrumb) {
      this.routeName = this.$route.meta.breadcrumb.parent;
    } else {
      this.routeName = this.$route.name;
    }

    this.getRoute();
  },

  methods: {
    /** ホバー時のナビゲーション開閉 */
    hoverSidebar(val) {
      const el = document.body;
      if (this.showMenu === false && window.innerWidth >= 1200) {
        val
          ? (el.className = "g-sidenav-show")
          : (el.className = "g-sidenav-hidden");
      }
    },

    /** クリック時のナビゲーション開閉 */
    toggleMenu() {
      this.showMenu = !this.showMenu;
      const el = document.body.classList;
      this.showMenu
        ? (el.add("g-sidenav-pinned"),
          this.$cookies.set("partner_sidenav", "pinned"))
        : (el.remove("g-sidenav-pinned"),
          this.$cookies.set("partner_sidenav", "unpinned"));
    },

    closeMenu() {
      const el = document.body;
      el.className = "g-sidenav-hidden";
    },

    /** ナビゲーションのアクティブ判定 */
    getRoute() {
      if (this.$route.meta.breadcrumb) {
        this.routeName = this.$route.meta.breadcrumb.parent;
      } else {
        this.routeName = this.$route.name;
      }
    },
  },
};
</script>

<style lang="scss"></style>
