import LoginRepository from "./loginRepository";
import LogoutRepository from "./logoutRepository";
import AuthRepository from "./authRepository";
import passwordRepository from "./passwordRepository";
import contractorRepository from "./contractorRepository";
import siteRepository from "./siteRepository";
import profileRepository from "./profileRepository";
import reportRepository from "./reportRepository";
import masterRepository from "./masterRepository";

const repositories = {
  login: LoginRepository,
  logout: LogoutRepository,
  auth: AuthRepository,
  password: passwordRepository,
  contractor: contractorRepository,
  site: siteRepository,
  profile: profileRepository,
  report: reportRepository,
  master: masterRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
