import Repository from "./repository";
import store from "@/store/index.js";

const resource = "/profile";

export default {
  index() {
    const contractorId = store.state.id;
    return Repository.get(`${contractorId}${resource}`);
  },
};
