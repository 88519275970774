<template>
  <div>
    <dashboard-navbar />
    <div class="main-content" id="panel">
      <!-- topnav -->
      <nav
        class="
          navbar navbar-top navbar-expand navbar-dark
          iv-partner-bg
          border-bottom
        "
      >
        <div class="container-fluid">
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <!-- Navbar links -->
            <ul class="navbar-nav align-items-center ml-md-auto">
              <li class="nav-item d-xl-none">
                <!-- Sidenav toggler -->
                <div
                  @click="toggleMenu()"
                  :class="{ active: showMenu }"
                  class="pr-3 sidenav-toggler sidenav-toggler-dark"
                >
                  <div class="sidenav-toggler-inner">
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                    <i class="sidenav-toggler-line"></i>
                  </div>
                </div>
              </li>
            </ul>
            <ul class="navbar-nav align-items-center ml-auto ml-md-0">
              <li class="nav-item dropdown">
                <base-dropdown position="right" class="nav-link pr-0">
                  <template v-slot:title>
                    <div
                      class="media align-items-center"
                      style="cursor: pointer"
                    >
                      <!-- Avatar -->
                      <div class="media-body ml-2 d-none d-lg-block">
                        <p
                          class="
                            mb-0
                            text-lg text-white text-right
                            font-weight-bold
                          "
                        >
                          {{ contractorName }}
                        </p>
                        <p
                          class="
                            mb-0
                            text-sm text-white text-right
                            font-weight-bold
                          "
                        >
                          {{ userName }}
                        </p>
                      </div>
                    </div>
                  </template>
                  <div class="dropdown-header noti-title">
                    <h6 class="text-overflow m-0">
                      {{ $t("dashboard.title.partner") }}
                    </h6>
                  </div>
                  <router-link :to="{ name: 'profile' }" class="dropdown-item">
                    <i class="ni ni-single-02"></i>
                    <span>{{ $t("dashboard.feature.profile") }}</span>
                  </router-link>
                  <div class="dropdown-divider"></div>
                  <span
                    @click="logout()"
                    class="dropdown-item"
                    style="cursor: pointer"
                  >
                    <i class="ni ni-user-run"></i>
                    <span>{{ $t("dashboard.items.logout") }}</span>
                  </span>
                </base-dropdown>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <!-- topnav -->

      <div class="header iv-partner-bg pb-6">
        <div class="container-fluid">
          <div class="header-body">
            <div class="row align-items-center py-4">
              <div class="col-lg-6 col-7 d-flex align-items-center">
                <h2 class="text-white mr-3">
                  {{ $t("dashboard.feature." + routeName) }}
                </h2>
                <dashboard-breadcrumb />
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-view class="mt--6" />
      <content-footer />
    </div>
  </div>
</template>

<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import DashboardBreadcrumb from "./DashboardBreadcrumb.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    DashboardBreadcrumb,
  },

  data() {
    return {
      showMenu: false,
      displayWidth: "",
      userName: "",
      contractorName: "",
    };
  },

  computed: {
    routeName() {
      return this.$route.name;
    },
  },

  created() {
    const self = this;

    /** パートナー情報取得 */
    const contractorRepository = self.$repository.get("contractor");
    const contractor = contractorRepository.index();
    contractor.then(
      function (result) {
        const tmp = result.data.response.data;
        self.contractorName = tmp.name;
      },
      function (error) {
        const res = error.response;
        if (res.status === 401) {
          /** 認証失敗時の処理 */
          self.$cookies.remove("partner_access_token");
          self.$cookies.remove("partner_contractor_id");
          self.$router.push({ name: "login" });
        } else {
          self.$router.push("/" + res.status);
        }
      }
    );

    /** ユーザー情報取得 */
    const profileRepository = self.$repository.get("profile");
    const profile = profileRepository.index();
    profile.then(
      function (result) {
        const tmp = result.data.response.data;
        self.userName = tmp.name;
      },
      function (error) {
        const res = error.response;
        if (res.status === 401) {
          /** 認証失敗時の処理 */
          self.$cookies.remove("partner_access_token");
          self.$cookies.remove("partner_contractor_id");
          self.$router.push({ name: "login" });
        } else {
          self.$router.push("/" + res.status);
        }
      }
    );

    /** ローカルストレージ内のマスターデータ有無 */
    const options = this.$store.state.master.options;
    const plans = this.$store.state.master.plans;
    const themes = this.$store.state.master.themes;
    const colors = this.$store.state.master.theme_colors;
    const contractors = this.$store.state.master.contractor_types;
    const resources = this.$store.state.master.resource_types;

    const buf = [options, plans, themes, colors, contractors, resources];
    const storageData = buf.some((value) => value === null);

    /** マスターデータをローカルストレージに保存 */
    if (storageData) {
      const masterRepository = this.$repository.get("master");
      const master = masterRepository.index();
      master.then(
        function (result) {
          const tmp = result.data.response.data;
          localStorage.setItem("iv_mst_options", JSON.stringify(tmp.options));
          localStorage.setItem("iv_mst_plans", JSON.stringify(tmp.plans));
          localStorage.setItem("iv_mst_themes", JSON.stringify(tmp.themes));
          localStorage.setItem(
            "iv_mst_theme_colors",
            JSON.stringify(tmp.theme_colors)
          );
          localStorage.setItem(
            "iv_mst_contractor_types",
            JSON.stringify(tmp.contractor_types)
          );
          localStorage.setItem(
            "iv_mst_resource_types",
            JSON.stringify(tmp.resource_types)
          );
          self.$store.dispatch("setMaster");
        },
        function (error) {
          const res = error.response;
          self.$router.push("/" + res.status);
        }
      );
    }
  },

  mounted() {
    this.displayWidth = window.innerWidth;

    /** サイドナビゲーションの状態取得 */
    const sidenav = this.$cookies.get("partner_sidenav");
    const el = document.body;
    if (sidenav == "unpinned" || this.displayWidth < 1200) {
      el.className = "g-sidenav-hidden";
    } else {
      el.className = "g-sidenav-show g-sidenav-pinned";
      this.$cookies.set("partner_sidenav", "pinned");
    }
  },

  methods: {
    /** サイドナビゲーションの開閉 */
    toggleMenu() {
      if (this.displayWidth < 768) {
        this.openMenu();
      } else {
        this.showMenu = !this.showMenu;
        this.showMenu ? this.openMenu() : this.closeMenu();
      }
    },

    openMenu() {
      const el = document.body;
      el.className = "g-sidenav-show g-sidenav-pinned";
    },

    closeMenu() {
      const el = document.body;
      el.className = "g-sidenav-hidden";
    },

    logout() {
      /** cookie内のアクセストークンとパートナーIDを削除 */
      const self = this;
      const logoutRepository = this.$repository.get("logout");
      const logout = logoutRepository.logout();
      logout
        .then(function () {
          self.$cookies.remove("partner_access_token");
          self.$cookies.remove("partner_contractor_id");
          self.$router.push({ name: "login" });
        })
        .catch(function (error) {
          const res = error.response;
          self.$router.push({ name: res.status });
        });
    },
  },
};
</script>

<style lang="scss"></style>
