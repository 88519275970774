var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group",class:[
    { 'has-danger': _vm.error },
    { 'has-label': _vm.label || _vm.$slots.label },
    { 'has-success': _vm.valid === true },
    { 'has-danger': _vm.valid === false },
    _vm.formClasses ]},[_c('div',{class:[
      { 'input-group': _vm.hasIcon },
      { focused: _vm.focused },
      _vm.inputGroupClasses ]},[_vm._t("label",function(){return [(_vm.label)?_c('label',{staticClass:"form-control-label",class:_vm.labelClasses},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e()]}),(_vm.addonLeftIcon || _vm.$slots.addonLeft)?_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_vm._t("addonLeft",function(){return [_c('i',{class:_vm.addonLeftIcon})]})],2)]):_vm._e(),_vm._t("default",function(){return [_c('input',_vm._b({staticClass:"form-control",class:[
          { 'is-valid': _vm.valid === true },
          { 'is-invalid': _vm.valid === false },
          _vm.inputClasses ],attrs:{"type":_vm.inputType,"aria-describedby":"addon-right addon-left"},domProps:{"value":_vm.value},on:{"input":_vm.updateValue,"focus":_vm.onFocus,"blur":_vm.onBlur}},'input',_vm.$attrs,false))]}),(_vm.addonRightIcon || _vm.$slots.addonRight)?_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._t("addonRight",function(){return [(!_vm.password)?_c('i',{class:_vm.addonRightIcon}):_vm._e(),(_vm.password)?_c('i',{staticClass:"iv-password__preview",class:_vm.passwordIcon,staticStyle:{"cursor":"pointer"},on:{"click":_vm.viewChange}}):_vm._e()]})],2)]):_vm._e(),_vm._t("infoBlock"),_vm._t("helpBlock",function(){return [(_vm.error && _vm.errorActive)?_c('div',{staticClass:"text-danger invalid-feedback",class:{ 'mt-2': _vm.hasIcon },staticStyle:{"display":"block"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }