var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('Search',{on:{"searchActive":_vm.setFielde}}),(_vm.listDisplay)?_c('div',[(_vm.sites.length)?_c('base-card',{attrs:{"footer-classes":"d-flex justify-content-end"},scopedSlots:_vm._u([(_vm.meta.last_page > 1)?{key:"footer",fn:function(){return [_c('base-pagination',{attrs:{"page-count":_vm.meta.last_page},on:{"input":_vm.pageChange},model:{value:(_vm.meta.current_page),callback:function ($$v) {_vm.$set(_vm.meta, "current_page", $$v)},expression:"meta.current_page"}})]},proxy:true}:null],null,true)},_vm._l((_vm.sites),function(site,index){return _c('div',{key:site.id,staticClass:"card",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"d-md-flex justify-content-between"},[_c('router-link',{attrs:{"to":{
                name: 'site_show',
                params: { site_id: site.id },
              }}},[_c('h3',{staticClass:"text-xl"},[_vm._v(_vm._s(site.origin_name))])]),_c('div',[_c('div',{staticClass:"d-inline-block",staticStyle:{"white-space":"nowrap"}},[_c('span',{staticClass:"badge iv-partner-bg text-white mr-2"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.items.plan"))+" ")]),_c('span',{staticClass:"text-sm"},[_vm._v(_vm._s(_vm.planName(site.plan_id)))])])])],1)]),_c('div',{staticClass:"card-body iv-report__accordion",class:{ open: _vm.show[index] }},[(site.ga_report)?_c('div',{staticClass:"row"},_vm._l((_vm.$t('dashboard.report.data')),function(report){return _c('div',{key:report.segment,staticClass:"col-xl-3 col-lg-6"},[(report.segment == 'avg_session_duration')?_c('base-stats-card',{staticClass:"mb-4 mb-xl-0",attrs:{"title":report.title,"type":report.color,"sub-title":_vm.dateNumber(site.ga_report[report.segment]),"unit-text":report.unit,"icon":report.icon},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('span',{staticClass:"mr-2",class:'text-' +
                      _vm.rateText(
                        report.segment,
                        site.ga_report['growth_rate_' + report.segment]
                      )},[_c('i',{class:'fa fa-arrow-' +
                        _vm.rateIcon(
                          site.ga_report['growth_rate_' + report.segment]
                        )}),_c('span',[_vm._v(" "+_vm._s(_vm.rateNumber( site.ga_report["growth_rate_" + report.segment] ))+"% ")])]),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.report.rate"))+" ")])]},proxy:true}],null,true)}):_c('base-stats-card',{staticClass:"mb-4 mb-xl-0",attrs:{"title":report.title,"type":report.color,"sub-title":_vm.resourceNumber(site.ga_report[report.segment]),"unit-text":report.unit,"icon":report.icon},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('span',{staticClass:"mr-2",class:'text-' +
                      _vm.rateText(
                        report.segment,
                        site.ga_report['growth_rate_' + report.segment]
                      )},[_c('i',{class:'fa fa-arrow-' +
                        _vm.rateIcon(
                          site.ga_report['growth_rate_' + report.segment]
                        )}),_c('span',[_vm._v(" "+_vm._s(_vm.rateNumber( site.ga_report["growth_rate_" + report.segment] ))+"% ")])]),_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.report.rate"))+" ")])]},proxy:true}],null,true)})],1)}),0):_c('div',{staticClass:"iv-report__accordion--no-data text-center"},[_c('h3',{staticClass:"iv-report__accordion--no-data__text"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.message.not_report"))+" ")])])]),_c('div',{staticClass:"card-footer text-center border-0",staticStyle:{"z-index":"1","cursor":"pointer"},on:{"click":function($event){return _vm.toggleAccordion(index)}}},[_c('span',{staticClass:"iv-report__accordion--arrow",class:{ open: _vm.show[index] }})])])}),0):_c('base-card',{staticClass:"text-center"},[_c('h3',[_vm._v(_vm._s(_vm.$t("dashboard.message.not_search")))]),_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.$t("dashboard.message.not_search_summary"))+" ")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }