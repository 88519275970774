import Repository from "./repository";

export default {
  login(fields) {
    return Repository.post("/login", {
      username: fields.email,
      password: fields.password,
    });
  },
};
