import Repository from "./repository";
import store from "@/store/index.js";

const resource = "/sites";

export default {
  index(fields, page) {
    const contractorId = store.state.id;
    return Repository.get(`${contractorId}${resource}`, {
      params: {
        page: page,
        plan_id: fields.plan,
        origin_name: fields.origin_name,
        resources_type: fields.resources_type,
        resources_order: fields.resources_order,
      },
    });
  },

  show(id) {
    const contractorId = store.state.id;
    return Repository.get(`${contractorId}${resource}/${id}`);
  },
};
