<template>
  <div class="main-content">
    <div class="header iv-partner-bg py-6 bg-iv">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1>
                <router-link :to="{ name: 'login' }">
                  <img
                    :src="storage + logo"
                    style="max-height: 40px"
                    alt="ivalue"
                  />
                </router-link>
              </h1>
              <p v-if="show" class="text-lead text-white">
                {{ $t("auth.message.login") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      storage: "",
      logo: "",
      show: false,
    };
  },

  // computed: {
  //   routeName() {
  //     return this.$route.name;
  //   },
  // },

  created() {
    this.storage = process.env.VUE_APP_STORAGE_URL;
    this.logo = process.env.VUE_APP_STORAGE_LOGO_WHITE_URL;

    /** cookieにアクセストークンがあればサイト一覧画面にリダイレクト */
    const token = this.$cookies.get("partner_access_token");
    const id = this.$cookies.get("partner_contractor_id");
    if (token && id) {
      this.$router.push({ name: "site_list" });
    } else {
      this.show = true;
    }
  },
};
</script>

<style lang="scss"></style>
