import BaseButton from "../components/BaseButton";
import BaseCard from "../components/BaseCard";
import BaseDropdown from "../components/BaseDropdown";
import BaseInput from "../components/BaseInput";
import BaseModal from "../components/BaseModal";
import BasePagination from "../components/BasePagination";
import BaseStatsCard from "../components/BaseStatsCard";

const GlobalComponents = {
  install(app) {
    app.component("base-button", BaseButton);
    app.component("base-card", BaseCard);
    app.component("base-dropdown", BaseDropdown);
    app.component("base-input", BaseInput);
    app.component("base-modal", BaseModal);
    app.component("base-pagination", BasePagination);
    app.component("base-stats-card", BaseStatsCard);
  },
};

export default GlobalComponents;
