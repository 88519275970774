<template>
  <div class="container-fluid">
    <Search @searchActive="setFielde" />
    <div v-if="listDisplay">
      <base-card
        v-if="sites.length"
        footer-classes="d-flex justify-content-end"
      >
        <div
          v-for="(site, index) in sites"
          :key="site.id"
          class="card"
          style="overflow: hidden"
        >
          <div class="card-header">
            <div class="d-md-flex justify-content-between">
              <router-link
                :to="{
                  name: 'site_show',
                  params: { site_id: site.id },
                }"
              >
                <h3 class="text-xl">{{ site.origin_name }}</h3>
              </router-link>
              <div>
                <div class="d-inline-block" style="white-space: nowrap">
                  <!-- <i class="fas fa-yen-sign mr-2"></i> -->
                  <!-- <i class="fas fa-file-invoice-dollar mr-2"></i> -->
                  <span class="badge iv-partner-bg text-white mr-2">
                    {{ $t("dashboard.items.plan") }}
                  </span>
                  <span class="text-sm">{{ planName(site.plan_id) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-body iv-report__accordion"
            :class="{ open: show[index] }"
          >
            <div v-if="site.ga_report" class="row">
              <div
                v-for="report in $t('dashboard.report.data')"
                :key="report.segment"
                class="col-xl-3 col-lg-6"
              >
                <base-stats-card
                  v-if="report.segment == 'avg_session_duration'"
                  :title="report.title"
                  :type="report.color"
                  :sub-title="dateNumber(site.ga_report[report.segment])"
                  :unit-text="report.unit"
                  :icon="report.icon"
                  class="mb-4 mb-xl-0"
                >
                  <template v-slot:footer>
                    <span
                      class="mr-2"
                      :class="
                        'text-' +
                        rateText(
                          report.segment,
                          site.ga_report['growth_rate_' + report.segment]
                        )
                      "
                    >
                      <i
                        :class="
                          'fa fa-arrow-' +
                          rateIcon(
                            site.ga_report['growth_rate_' + report.segment]
                          )
                        "
                      ></i>
                      <span>
                        {{
                          rateNumber(
                            site.ga_report["growth_rate_" + report.segment]
                          )
                        }}%
                      </span>
                    </span>
                    <span class="text-nowrap">
                      {{ $t("dashboard.report.rate") }}
                    </span>
                  </template>
                </base-stats-card>
                <base-stats-card
                  v-else
                  :title="report.title"
                  :type="report.color"
                  :sub-title="resourceNumber(site.ga_report[report.segment])"
                  :unit-text="report.unit"
                  :icon="report.icon"
                  class="mb-4 mb-xl-0"
                >
                  <template v-slot:footer>
                    <span
                      class="mr-2"
                      :class="
                        'text-' +
                        rateText(
                          report.segment,
                          site.ga_report['growth_rate_' + report.segment]
                        )
                      "
                    >
                      <i
                        :class="
                          'fa fa-arrow-' +
                          rateIcon(
                            site.ga_report['growth_rate_' + report.segment]
                          )
                        "
                      ></i>
                      <span>
                        {{
                          rateNumber(
                            site.ga_report["growth_rate_" + report.segment]
                          )
                        }}%
                      </span>
                    </span>
                    <span class="text-nowrap">
                      {{ $t("dashboard.report.rate") }}
                    </span>
                  </template>
                </base-stats-card>
              </div>
            </div>
            <div v-else class="iv-report__accordion--no-data text-center">
              <h3 class="iv-report__accordion--no-data__text">
                {{ $t("dashboard.message.not_report") }}
              </h3>
            </div>
          </div>
          <div
            @click="toggleAccordion(index)"
            class="card-footer text-center border-0"
            style="z-index: 1; cursor: pointer"
          >
            <span
              class="iv-report__accordion--arrow"
              :class="{ open: show[index] }"
            ></span>
          </div>
        </div>
        <template v-slot:footer v-if="meta.last_page > 1">
          <base-pagination
            :page-count="meta.last_page"
            v-model="meta.current_page"
            @input="pageChange"
          ></base-pagination>
        </template>
      </base-card>
      <base-card v-else class="text-center">
        <h3>{{ $t("dashboard.message.not_search") }}</h3>
        <p class="m-0">
          {{ $t("dashboard.message.not_search_summary") }}
        </p>
      </base-card>
    </div>
  </div>
</template>

<script>
import Search from "./SiteListSearch.vue";

export default {
  components: {
    Search,
  },

  data() {
    return {
      showAccordion: false,
      listDisplay: false,
      pageNumber: 1,
      show: {},
      sites: [],
      links: {},
      meta: {},
      fields: {},
    };
  },

  mounted() {
    this.getSites();
  },

  methods: {
    /** サイトデータ取得 */
    getSites() {
      this.listDisplay = false;
      this.show = {};
      const SiteRepository = this.$repository.get("site");
      const site = SiteRepository.index(this.fields, this.pageNumber);
      const self = this;
      site.then(
        function (result) {
          const res = result.data.response;
          self.sites = res.data;
          self.link = res.link;
          self.meta = res.meta;
          self.listDisplay = true;
          window.scrollTo({
            top: 0,
          });
        },
        function (error) {
          const res = error.response;
          if (res.status === 401) {
            /** 認証失敗時の処理 */
            self.$cookies.remove("partner_access_token");
            self.$cookies.remove("partner_contractor_id");
            self.$router.push({ name: "login" });
          } else {
            self.$router.push("/" + res.status);
          }
        }
      );
    },

    /** アコーディオンの開閉 */
    toggleAccordion(key) {
      this.$set(this.show, key, !this.show[key]);
    },

    /** rateの値によるスタイルの変更 */
    rateText(val, num) {
      if (val == "bounce_rate") {
        return num <= 0 ? "success" : "danger";
      } else {
        return num >= 0 ? "success" : "danger";
      }
    },

    /** rateの値によるアイコンの変更 */
    rateIcon(num) {
      if (num > 0) {
        return "fa fa-arrow-up";
      } else if (num < 0) {
        return "fa fa-arrow-down";
      } else {
        return "fa fa-arrow-right";
      }
    },

    /** rateのフォーマット変更 */
    rateNumber(val) {
      return Math.round(val * Math.pow(10, 2)) / Math.pow(10, 2);
    },

    /** レポート指標の数値にカンマを挿入 */
    resourceNumber(val) {
      return Number(val).toLocaleString();
    },

    /** 平均滞在時間のフォーマット変更 */
    dateNumber(val) {
      var hour = Math.floor(val / 3600);
      var min = Math.floor(val / 60) % 60;
      var sec = Math.round(val % 60) % 60;
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (min < 10) {
        min = "0" + min;
      }
      if (sec < 10) {
        sec = "0" + sec;
      }
      return hour + ":" + min + ":" + sec;
    },

    /** プランの名称取得 */
    planName(val) {
      var tmp = this.$store.state.master.plans.filter((e) => e.id == val);
      return tmp[0].name;
    },

    /** 検索メソッド */
    setFielde(item) {
      this.fields = item;
      this.pageNumber = 1;
      this.getSites();
    },

    /** ページネーションメソッド */
    pageChange(num) {
      this.pageNumber = num;
      this.getSites();
    },
  },
};
</script>

<style lang="scss">
.iv-report__accordion {
  transition: 0.3s;
  min-height: 0;
  height: 0;
  padding: 0 2.75rem;
  opacity: 0;
  visibility: hidden;

  &.open {
    padding: 1.5rem 2.75rem;
    opacity: 1;
    height: 200px;
    visibility: inherit;
  }

  &--arrow {
    position: relative;

    &::before,
    &::after {
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      transition: 0.3s;
    }

    &::before {
      border-left: 1px solid #000;
    }

    &::after {
      border-bottom: 1px solid #000;
    }

    &.open {
      &::before,
      &::after {
        transform: translate(-50%, -20%) rotate(135deg);
      }
    }
  }

  &--no-data {
    position: relative;
    height: 100%;

    &__text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .iv-report__accordion {
    &.open {
      height: 300px;
    }
  }
}

@media (max-width: 991px) {
  .iv-report__accordion {
    &.open {
      height: 630px;
    }
  }
}
</style>
