import axios from "axios";
import store from "@/store/index.js";

const baseURL =
  process.env.VUE_APP_API_BASE_URL + process.env.VUE_APP_API_VERSION;
const token = store.state.token;

// axiosのインスタンス作成
export default axios.create({
  baseURL,
  headers: { Authorization: `Bearer ${token}` },
});
