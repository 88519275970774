<template>
  <div v-if="pageActive" class="container-fluid">
    <base-card header-classes="border-0">
      <template v-slot:header>
        <h3 class="m-0">{{ $t("dashboard.title.info") }}</h3>
      </template>
      <div class="row mb-md-3">
        <div class="col-md-3">
          <p class="text-sm font-weight-bold m-0">
            {{ $t("dashboard.items.origin") }}
          </p>
        </div>
        <div class="col-md-9">
          <p>
            {{ site.origin_name }}
            <a :href="site.origin_name" target="_blank"
              ><i class="fas fa-external-link-alt ml-2"></i
            ></a>
          </p>
        </div>
      </div>
      <div class="row mb-md-3">
        <div class="col-md-3">
          <p class="text-sm font-weight-bold m-0">
            {{ $t("dashboard.items.site_id") }}
          </p>
        </div>
        <div class="col-md-9">
          <p>{{ site.id }}</p>
        </div>
      </div>
      <div class="row mb-md-3">
        <div class="col-md-3">
          <p class="text-sm font-weight-bold m-0">
            {{ $t("dashboard.items.contract") }}
          </p>
        </div>
        <div class="col-md-9">
          <p>
            {{ contractName(site.contract_type) }}
            <span v-if="site.contract_type == 1">
              ( {{ site.expiration_at | moment("YYYY年MM月DD日") }}
              {{ $t("general.end") }} )
            </span>
          </p>
        </div>
      </div>
      <div class="row mb-md-3">
        <div class="col-md-3">
          <p class="text-sm font-weight-bold m-0">
            {{ $t("dashboard.items.plan") }}
          </p>
        </div>
        <div class="col-md-9">
          <p>{{ planName(site.plan_id) }}</p>
        </div>
      </div>
      <div class="row mb-md-3">
        <div class="col-md-3">
          <p class="text-sm font-weight-bold m-0">
            {{ $t("dashboard.items.theme") }}
          </p>
        </div>
        <div class="col-md-9">
          <p>{{ themeName(site.theme_id) }}</p>
        </div>
      </div>
      <div class="row mb-md-3">
        <div class="col-md-3">
          <p class="text-sm font-weight-bold m-0">
            {{ $t("dashboard.items.theme_color") }}
          </p>
        </div>
        <div class="col-md-9">
          <p>{{ colorName(site.theme_color_id) }}</p>
        </div>
      </div>
      <div v-if="site.plan_id == 1 || site.plan_id == 4" class="row mb-md-3">
        <div class="col-md-3">
          <p class="text-sm font-weight-bold m-0">
            {{ $t("dashboard.items.option") }}
          </p>
        </div>
        <div class="col-md-9">
          <p v-if="site.options.length">
            <span v-for="option in site.options" :key="option.id" class="mr-3">
              {{ option.option_name }}
            </span>
          </p>
          <p v-else>
            {{ $t("dashboard.items.not_used") }}
          </p>
        </div>
      </div>
      <div class="row mb-md-3">
        <div class="col-md-3">
          <p class="text-sm font-weight-bold m-0">
            {{ $t("dashboard.items.limit_freepage") }}
          </p>
        </div>
        <div class="col-md-9">
          <p>{{ site.freepage_number }}</p>
        </div>
      </div>
      <div class="row mb-md-3">
        <div class="col-md-3">
          <p class="text-sm font-weight-bold m-0">
            {{ $t("dashboard.items.limit_mail_account") }}
          </p>
        </div>
        <div class="col-md-9">
          <p>{{ site.mail_account_number }}</p>
        </div>
      </div>
      <div class="row mb-md-3">
        <div class="col-md-3">
          <p class="text-sm font-weight-bold m-0">
            {{ $t("dashboard.items.contractor") }}
          </p>
        </div>
        <div class="col-md-9">
          <p>{{ site.contractor.name }}</p>
        </div>
      </div>
      <div class="row mb-md-3">
        <div class="col-md-3">
          <p class="text-sm font-weight-bold m-0">
            {{ $t("dashboard.items.open_at") }}
          </p>
        </div>
        <div class="col-md-9">
          <p>{{ site.open_at | moment("YYYY年MM月DD日") }}</p>
        </div>
      </div>
    </base-card>
    <Report />
  </div>
</template>

<script>
import Report from "./SiteShowReport.vue";

export default {
  components: {
    Report,
  },

  data() {
    return {
      site_id: this.$route.params["site_id"],
      site: {},
      fields: {
        monthly: "",
      },
      pageActive: false,
    };
  },

  created() {
    /** サイトデータ取得 */
    const SiteRepository = this.$repository.get("site");
    const site = SiteRepository.show(this.site_id);
    const self = this;
    site.then(
      function (result) {
        const res = result.data.response;
        self.site = res.data;
        self.pageActive = true;
      },
      function (error) {
        const res = error.response;
        if (res.status === 401) {
          /** 認証失敗時の処理 */
          self.$cookies.remove("partner_access_token");
          self.$cookies.remove("partner_contractor_id");
          self.$router.push({ name: "login" });
        } else {
          self.$router.push("/" + res.status);
        }
      }
    );
  },

  methods: {
    /** 契約区分の名称取得 */
    contractName(val) {
      var tmp = this.$store.state.master.contractor_types.filter(
        (e) => e.id == val
      );
      return tmp[0].name;
    },

    /** プランの名称取得 */
    planName(val) {
      var tmp = this.$store.state.master.plans.filter((e) => e.id == val);
      return tmp[0].name;
    },

    /** テーマの名称取得 */
    themeName(val) {
      var tmp = this.$store.state.master.themes.filter((e) => e.id == val);
      return tmp[0].name;
    },

    /** テーマカラーの名称取得 */
    colorName(val) {
      var tmp = this.$store.state.master.theme_colors.filter(
        (e) => e.id == val
      );
      return tmp[0].label;
    },
  },
};
</script>

<style lang="scss"></style>
