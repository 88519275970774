<template>
  <div class="container-fluid">
    <base-card header-classes="border-0">
      <template v-slot:header>
        <h3>{{ $t("dashboard.title.profile") }}</h3>
      </template>
      <form @submit.prevent="submit">
        <div class="form-row">
          <label class="col-form-label form-control-label col-md-3">
            {{ $t("dashboard.items.user") }}
          </label>
          <div class="col-md-4">
            <base-input
              type="text"
              input-classes="form-control"
              v-model="name"
              disabled
            >
            </base-input>
          </div>
        </div>
        <div class="row mt-md-1 mb-3">
          <div class="col-md-3">
            <p class="text-sm font-weight-bold m-0">
              {{ $t("dashboard.items.email") }}
            </p>
          </div>
          <div class="col-md-9">
            <p>{{ email }}</p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-3">
            <p class="text-sm font-weight-bold m-0">
              {{ $t("dashboard.items.password") }}
            </p>
          </div>
          <div class="col-md-4">
            <p>******</p>
          </div>
          <!-- <div class="col-md-2">
            <base-button :type="null" class="iv-partner-btn" @click="modal = true">
              {{ $t("general.change") }}
            </base-button>
          </div> -->
        </div>
        <!-- <div class="text-right">
          <base-button :type="null" class="iv-partner-btn" native-type="submit">
            {{ $t("general.do_something", { name: $t("general.update") }) }}
          </base-button>
        </div> -->
      </form>
    </base-card>
    <!-- <ProfilePassword :form="modal" @close-modal="close" /> -->
  </div>
</template>

<script>
// import ProfilePassword from "./ProfilePassword.vue";

export default {
  // components: {
  //   ProfilePassword,
  // },

  data() {
    return {
      name: "",
      email: "",
      modal: false,
    };
  },

  created() {
    const ProfileRepository = this.$repository.get("profile");
    const profile = ProfileRepository.index();
    const self = this;
    profile.then(
      function (result) {
        const res = result.data.response.data;
        self.name = res.name;
        self.email = res.email;
      },
      function (error) {
        const res = error.response;
        if (res.status === 401) {
          /** 認証失敗時の処理 */
          self.$cookies.remove("partner_access_token");
          self.$cookies.remove("partner_contractor_id");
          self.$router.push({ name: "login" });
        } else {
          self.$router.push("/" + res.status);
        }
      }
    );
  },

  methods: {
    // submit() {
    //   this.$swal({
    //     icon: "success",
    //     title: this.$t("dashboard.feature.profile"),
    //     text: this.$t("general.message", {
    //       name: this.$t("dashboard.feature.profile"),
    //       verb: this.$t("general.update"),
    //     }),
    //     confirmButtonText: this.$t("general.close"),
    //     timer: 5000,
    //   });
    // },
    // close() {
    //   this.modal = false;
    // },
  },
};
</script>

<style lang="scss"></style>
